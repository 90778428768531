/*

.Main-bg {
    visibility:hidden;
}


*/

.full-width {
    position: absolute;
    left: 0px;
    right: 0px;
}

.sbox1 {
    background-color: rgba(211, 211, 211,0.5);
    height: 70vh;
    overflow: auto;
}

.sbox {
    justify-content: center;
    padding-top: 20vh;
    padding-left: 10vw;
    overflow: auto;
}

.stext-box {
    width: 70%;
    position: relative;
}

.stitle {
    text-align: left;
    font-size: 6vh;
}

.scenter_para {
    text-align: left;
    font-size: 2.5vh;
}

.sbox2 {
    background-color: rgba(211, 211, 211,0.5);
    height: 50vh;
    overflow: auto;
}

.stitle2 {
    font-size: 6vh;
}



.sbox3 {
    background-color: rgba(255, 255, 255,0.5);
    height: 50vh;
    overflow: auto;
}

.scenter_para2 {
    text-align: left;
    font-size: 2.5vh;
}

.stext-box3 {
    width: 85%;
    position: relative;
}

.sbox2-sub {
    justify-content: center;
    padding-top: 3vh;
    padding-left: 5vw;
}

.sbox3-sub {
    justify-content: center;
    padding-top: 3vh;
    padding-left: 5vw;
}

.spicbox1 {
    padding-left: 0vw;
    padding-top: 0vh;
    background-image: url(./img/Services/business-report-1.png);
    background-position: center;
    background-size: cover;
    height: 70vh;
}

.spicbox2 {
    padding-left: 0vw;
    padding-top: 0vh;
    background-image: url(./img/Services/work-1.jpg);
    background-size: cover;
    height: 50vh;
    background-position: center;
    justify-content: center;
}

.spicbox3 {
    padding-left: 0vw;
    padding-top: 0vh;
    background-image: url(./img/Services/work-2.jpg);
    background-position: center;
    background-size: cover;
    height: 50vh;
}

.spicbox4 {
    padding-left: 0vw;
    padding-top: 0vh;
    background-image: url(./img/Services/work-3.jpg);
    background-position: center;
    background-size: cover;
    height: 50vh;
}

.spicbox5 {
    padding-left: 0vw;
    padding-top: 0vh;
    background-image: url(./img/Services/work-4.jpg);
    background-position: center;
    background-size: cover;
    height: 50vh;
}

.spicbox6 {
    padding-left: 0vw;
    padding-top: 0vh;
    background-image: url(./img/Services/work-5.jpg);
    background-position: center;
    background-size: cover;
    height: 50vh;
}

.spicbox7 {
    padding-left: 0vw;
    padding-top: 0vh;
    background-image: url(./img/Services/work-6.jpg);
    background-position: center;
    background-size: cover;
    height: 50vh;
}

.spicbox8 {
    padding-left: 0vw;
    padding-top: 0vh;
    background-image: url(./img/Services/work-7.jpg);
    background-position: center;
    background-size: cover;
    height: 50vh;
}
