.contact-form {
    max-width: 100vw;
    text-align: center;
    margin: 10vw auto;
}


textarea {
    border: 0;
    outline: 0;
    padding: 1vh;
    border-radius: 2vw;
    display: block;
    width: 100%;
    margin-top: 1vh;
    font-family: 'Merriweather', sans-serif;
    resize: none;
}

    textarea:hover {
        background-color: rgba(241, 90, 34, 0.2);
    }

.form-input {
    border: 0;
    outline: 0;
    padding: 1vh;
    border-radius: 2vw;
    display: block;
    width: 100%;
    margin-top: 1vh;
    font-family: 'Merriweather', sans-serif;
    resize: none;
    border-bottom: 0.1px solid gray;
}

    .form-input:hover {
        background-color: rgba(241, 90, 34, 0.2);
    }

service_checkbox {
    width: 10%;
    margin-top: 1vh;
    font-family: 'Merriweather', sans-serif;
    resize: none;
    border: 0;
    outline: 0;
    padding: 1vh;
    border-radius: 2vw;
    display: block;
}


#input-submit {
    color: white;
    background: #e74c3c;
    cursor: pointer;
}

    #input-submit:hover {
        box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.2);
    }

textarea {
    height: 20vh;
}

.contactform_header {
    font-weight: 500;
    text-align: left;
}

.half {
    float: left;
    width: 48%;
    margin-bottom: 1em;
}

.right {
    width: 50%;
}

.left {
    margin-right: 2%;
}

@media (max-width: 480px) {
    .half {
        width: 100%;
        float: none;
        margin-bottom: 0;
    }
}

.cf:before,
.cf:after {
    content: ' ';
    display: table;
}

.cf:after {
    clear: both;
}

.identity_dropdown {
    border-radius: 0.5vw;
    font-size:2vh;
}

.revenue_dropdown {
    border-radius: 0.5vw;
    font-size: 2vh;
}
