body, html {
    height: 100%;
    margin: 0;
}
.Main-component {
    /* Full height */
    height: 100vh;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
    margin-bottom: 1rem;
}



.container {
    border-radius: 8px;
    margin-bottom: 2rem;
}

.title {
    text-align: center;
}
.convincepics1 {
    /* The image used */
    content: url("./img/smile3-removebg.png");
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 25vh;
    width: auto;
    text-align: center;
    
}

.convincepics2 {
    /* The image used */
    content: url("./img/smile2-removebg.png");
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 25vh;
    width: auto;
    text-align: center;
}

.img-wrapper {
    overflow: hidden;
}

img.hover-zoom {
    transition: all 0.3s ease 0s;
    width: 100%;
}

img.hover-zoom:hover {
    transform: scale(0.7);
}

.bigcard1 {
    background-color: rgba(199, 232, 233,0.5);
    display: flex;
    justify-content: center;
    text-align: center;
    text-justify: inter-word;
    padding: 0.5vw;
}

.bigcard2 {
    background-color: rgb(16, 25, 71);
    display: flex;
    justify-content: center;
    color: white;
    text-align: center;
    text-justify: inter-word;
    padding: 0.5vw;
}

@media screen and (max-width: 600px) {
    .bigcard1 {
        display: none;
    }
    .bigcard2 {
        display: none;
    }
}

.card1 {
    content: url("./img/cards/accounting.png");
    transform: scale(0.4);
}

.card2 {
    content: url("./img/cards/financial-profit.png");
    transform: scale(0.4);
}

.card3 {
    content: url("./img/cards/consolidate.png");
    transform: scale(0.4);
}

.card4 {
    content: url("./img/cards/taxes.png");
    transform: scale(0.4);
}

.card5 {
    content: url("./img/seo-report.png");
    transform: scale(0.4);
}

.card6 {
    content: url("./img/report4.png");
    transform: scale(0.4);
}

.card7 {
    content: url("./img/clipboard.png");
    transform: scale(0.4);
}

.Cards {
    overflow: hidden;
    text-align: center;
}

.Main-bg {
    /*background-image: url(./components/img/female_sales.jpg);*/
    background-image: url(./img/digital_wallpaper.jpg);
    background-size: cover;
    height: 100vh;
    width: 100vw;
    filter: brightness(0.8);
    position: absolute;
    display: flex;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    justify-content: center;
}

.Main-bg-test {
    background-image: url(./img/latop_handphone_plant.jpg);
    background-size: cover;
    filter: brightness(0.7);
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    overflow: hidden;
    left: 0px;
    top: 0px;
    z-index: -2;
    /*
    min-height: 100%;
    <img class="Main-bg" />
    position: relative;
*/
}