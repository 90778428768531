a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
    text-shadow: 1px 4px 4px rgba(0,0,0,0.7);
    color: rgb(200,200,200);
}

.float_bar {
    background-color: rgba(100,100,100,0.3);
    transition: background-color linear 0.8s;
}


.float_bar:hover {
    background-color: rgba(0, 0, 0,0.5);
}


