/*

.Main-bg {
    visibility:hidden;
}


*/

@media screen and (min-width: 321px) and (max-width:960px) {
    .full-width {
        position: absolute;
        left: 0px;
        right: 0px;
    }
}

@media screen and (min-width: 720px) and (max-width:2000px) {
    .full-width {
        position: absolute;
        left: 0px;
        right: 0px;
        margin-left: 10vw;
        margin-right: 10vw;

    }
}




.box1 {
    background-color: rgba(211, 211, 211,0.5);
    height: 80vh;
    overflow: auto;
}

.box {
    justify-content: center;
    padding-top: 20vh;
    padding-left: 10vw;
    overflow: auto;
}

.text-box {
    width: 60%;
    position:relative;
}

.title {
    text-align: left;
    font-size:5vh;
}

.center_para {
    text-align: left;
    font-size: 2.4vh;
}

.picbox1 {
    padding-left: 0vw;
    padding-top: 0vh;
    background-image: url(./img/female_in_office.jpg);
    background-size: cover;
    height: 80vh;
}

.box2 {
    text-align: center;
    padding-top: 8vh;
    padding-bottom: 10vh;
}

.title2{
    font-size:6vh;
}

.picbox2 {
    padding-left: 0vw;
    padding-top: 0vh;
    background-image: url(./img/office1.jpg);
    background-size: cover;
    height: 90vh;
    justify-content: center;
}

.box3 {
    background-color: rgba(211, 211, 211,0.5);
    height: 90vh;
    overflow: auto;
}

.center_para2 {
    text-align: left;
    font-size: 1.8vh;
}

.text-box3 {
    width: 85%;
    position: relative;
}

.box3-sub {
    justify-content: center;
    padding-top: 10vh;
    padding-left: 5vw;
}
